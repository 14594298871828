import { AmountFor } from '@bts-web/data-layer/server';
import { getTargetAmount } from './amount-convertion';
import { PrecisionFloat } from './precision';

export const getTargetFiatAndAssetAmount = ({
  sourceAmount,
  targetType,
  price,
}: {
  price: PrecisionFloat;
  targetType: AmountFor;
  sourceAmount: PrecisionFloat;
}): {
  FIAT: PrecisionFloat;
  ASSET: PrecisionFloat;
} => {
  let fiatAmount: PrecisionFloat;

  let assetAmount: PrecisionFloat;

  if (targetType === 'ASSET') {
    assetAmount = sourceAmount;

    fiatAmount = getTargetAmount({
      targetType: 'FIAT',
      sourceAmount,
      price,
    });
  } else {
    fiatAmount = sourceAmount;

    assetAmount = getTargetAmount({
      targetType: 'ASSET',
      sourceAmount,
      price,
    });
  }

  return {
    FIAT: fiatAmount,
    ASSET: assetAmount,
  };
};
