'use client';

import { default as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import { useTranslation } from 'react-i18next';
import { getValueFromCookies } from '@bts-web/utils-storage';
import { codeSplitBackend, translationOptions } from './translation-options';
import type { NamespacedTranslation } from './translation-api.type';

const isServerRuntime = typeof window === 'undefined';

i18nInstance.use(initReactI18next).use(codeSplitBackend).init(getInitOptions());

function getInitOptions() {
  if (isServerRuntime) {
    // SSR
    // get server-side cookie language
    return translationOptions;
  }

  // read language
  const lng = getValueFromCookies('app_language') as string;

  return {
    ...translationOptions,
    lng,
  };
}

// server component: await changeLanguage ✅ (from cookie)
// SSR client-component: hook (sync); direct init ❌ (no cookies); changeLanguage is async
// browser component: direct init from document.cookie ✅

if (process.env.NODE_ENV === 'development' && typeof window === 'undefined') {
  console.info('ℹ️  Using universal translations.');
}

/**
 * Will print an info message on the server console (dev only)
 *
 * Please make sure we really need to use client-side translations
 *
 * (sending serve-side translated props should be the first option)
 */
export const useClientTranslation = () => {
  async function serverLanguageChange() {
    const { cookies } = await import('next/headers');

    const lng = cookies().get('app_language')?.value;

    if (i18nInstance.resolvedLanguage !== lng) {
      await i18nInstance.changeLanguage(lng);
    }
  }

  if (isServerRuntime) {
    serverLanguageChange();
  }

  return (
    useTranslation as NamespacedTranslation<UseTranslationApiWithoutTFunction>
  )();
};

type UseTranslationApiWithoutTFunction = OmitPropAndArrayKeys<
  ReturnType<typeof useTranslation>,
  't'
>;

type OmitPropAndArrayKeys<Type, Prop> = {
  [Key in keyof Type as Key extends Prop | keyof [] ? never : Key]: Type[Key];
};
