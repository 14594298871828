import { AmountFor } from '@bts-web/data-layer/server';
import { PrecisionFloat, getPrecisionFloatValue } from './precision';

type GetValidatedTradeInputDataProps = {
  amount: PrecisionFloat;
  translations: {
    errorTooHigh: string;
    errorTooLow: string;
    grams: string;
    inclSpread: string;
  };
  displayType: AmountFor;
  minimumTradeAmount?: PrecisionFloat;
  maximumTradeAmount?: PrecisionFloat;
};

export const getDecimalsByDisplayType = (displayType: AmountFor) =>
  displayType === 'FIAT' ? 2 : 8;

export const getValidatedTradeInputData = (
  params: GetValidatedTradeInputDataProps,
) => {
  const {
    amount,
    translations,
    displayType,
    minimumTradeAmount,
    maximumTradeAmount,
  } = params;

  const value = getPrecisionFloatValue(amount);

  const decimalsByDisplayType = getDecimalsByDisplayType(displayType);

  const currentTradeAmount = Number(
    Number(value).toFixed(decimalsByDisplayType),
  );

  const validTradeInputData: {
    amount: PrecisionFloat;
    amountFor: AmountFor;
    error: string;
  } = {
    amount: { value, precision: amount?.precision },
    amountFor: displayType,
    error: '',
  };

  const maximumTradeAmountValue = getPrecisionFloatValue(maximumTradeAmount);

  const minimumTradeAmountValue = getPrecisionFloatValue(minimumTradeAmount);

  if (!value || !maximumTradeAmountValue || !minimumTradeAmountValue) {
    return validTradeInputData;
  }

  if (Number(currentTradeAmount) > Number(maximumTradeAmountValue)) {
    return {
      ...validTradeInputData,
      error: translations.errorTooHigh,
    };
  }

  if (
    minimumTradeAmountValue &&
    Number(currentTradeAmount) < Number(minimumTradeAmountValue)
  ) {
    return {
      ...validTradeInputData,
      error: translations.errorTooLow,
    };
  }

  return validTradeInputData;
};

// returns the amount value of specific asset by display type.
export const getTargetAmount = ({
  targetType,
  price,
  sourceAmount,
}: {
  price: PrecisionFloat;
  targetType: AmountFor;
  sourceAmount: PrecisionFloat;
}): PrecisionFloat => {
  const sourceAmountNumber = Number(sourceAmount?.value);

  const priceValue = Number(price?.value);

  let targetAmount = 0;

  if (sourceAmountNumber > 0 && priceValue > 0) {
    targetAmount =
      targetType === 'ASSET'
        ? sourceAmountNumber / priceValue
        : sourceAmountNumber * priceValue;
  }

  return {
    value: targetAmount.toFixed(getDecimalsByDisplayType(targetType)),
    precision: getDecimalsByDisplayType(targetType),
  };
};
