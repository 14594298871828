export const Z_INDEX_LIBRARY = {
  // Z-Index for the top bar
  TOP_BAR: 1000,
  // Z-Index for the mobile navigation
  MOBILE_NAVIGATION: 2000,
  // Z-Index for the modal
  MODAL: 3000,
  // Z-Index for the dropdown
  DROPDOWN: 4000,
  // Z-Index for the tooltip
  TOOLTIP: 5000,
  // Z-Index for the notification
  NOTIFICATION: 6000,
  // Z-Index for the overlay
  OVERLAY: 7000,
  // Z-Index for the loader
  LOADER: 8000,
  // Z-Index for the toast
  TOAST: 10000,
  // Z-Index for the drawer
  DRAWER: 2500,
};
