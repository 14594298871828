export {
  i18n,
  initServerTranslation,
  updateServerLanguage,
} from './translation';

export {
  type MissingTranslationKey,
  type NamespacedTranslation,
} from './translation-api.type';

export {
  type Namespace,
  type NamespaceNames,
  type Namespaces,
} from './translation-namespaces.type';

export {
  type DefaultNS,
  type Language,
  type LanguageTitle,
  type Locale,
  codeSplitBackend,
  defaultLanguage,
  defaultLocale,
  getLocale,
  languageTitleByCode,
  translationOptions,
} from './translation-options';

export { useClientTranslation } from './translation.use-client';
