// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

const isBrowser = typeof window !== 'undefined';

const forBrowser = <F>(browserFunction: F) =>
  isBrowser ? browserFunction : noop;

export const getValueFromCookies = forBrowser(
  (key: string) =>
    document.cookie
      .split(';')
      .find((cookieEntry) => cookieEntry.indexOf(key) !== -1)
      ?.split('=')[1],
);

export const setCookieValue = forBrowser((key: string, value: string) => {
  document.cookie = `${key}=${value}; path=/; Secure;`;
});
