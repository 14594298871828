'use client';

import { TGQLErrorResponseExtensionCode } from '../gqlNetworkFetch/types';
import { useAppNotification } from '@bts-web/core-features/notifications';
import { translateErrorCodesOrReturnThemDirectly } from './translateErrorCodesOrReturnThemDirectly';

export const useHandleGqlErrorsWithSnackbar = () => {
  const { setAppNotification } = useAppNotification();

  const processErrors = async (
    gqlResponseErrors?: TGQLErrorResponseExtensionCode[],
  ) => {
    const { genericTitle, translatedCodes } =
      await translateErrorCodesOrReturnThemDirectly(gqlResponseErrors);

    setAppNotification({
      visual: 'error',
      title: genericTitle,
      subtitle: translatedCodes.join(', '),
    });
  };

  return { processErrors };
};
