export const LOCAL_STORAGE_MAIN_KEY = 'bts-web';

const localStorageKey = (key: string) => `${LOCAL_STORAGE_MAIN_KEY}_${key}`;

const localStorageOperations = () => {
  const isBrowser = typeof window !== 'undefined';

  return {
    getItem: (key: string) => {
      return isBrowser
        ? JSON.parse(<string>localStorage.getItem(localStorageKey(key)))
        : null;
    },
    setItem: (key: string, value: string | { [key: string]: any }) => {
      isBrowser &&
        localStorage.setItem(localStorageKey(key), JSON.stringify(value));
    },
    removeItem: (key: string) => {
      isBrowser && localStorage.removeItem(localStorageKey(key));
    },
    clear: () => {
      isBrowser && localStorage.clear();
    },
  };
};

export const storage = localStorageOperations();
