import { css } from '@bts-web/utils-style-engine';
import { Button } from '@bts-web/design-system/component/button';
import { ButtonComponentProps } from '../../types';

export type ButtonVisualVariants =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'accent'
  | 'primaryOnBrand';

export type ButtonSizeVariants = 'small' | 'medium' | 'large';

export type ButtonProps = ButtonComponentProps;

export const ButtonBase: (
  props: ButtonComponentProps,
) => React.ReactElement = ({
  size = 'medium',
  visual = 'accent',
  children,
  noPadding = false,
  fullWidth = false,
  ...rest
}) => (
  <Button
    aria-label={`button ${typeof children == 'string' ? children.toLowerCase() : ''}`}
    className={css({
      '&:has(a:only-child)': {
        px: 0,
        py: 0,

        '& > a': {
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      display: fullWidth ? 'flex' : 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      gap: 'extra_small_2',
      borderRadius: '8px',
      cursor: 'pointer',
      maxWidth: {
        base: !fullWidth ? 342 : '100%',
        lg: '100%',
      },
      width: fullWidth ? '100%' : 'auto',
      '& > [data-element="loading-icon"]': {
        animation: 'spin 1s linear infinite',
      },
      ...(visual === 'primary'
        ? {
            backgroundColor: 'neutrals.fill_primary',
            color: 'neutrals.text_primary_inverted',
            '&:active:enabled': {
              backgroundColor: 'neutrals.fill_pressed',
              color: 'neutrals.text_primary_inverted',
            },
            '&:focus:enabled': {
              backgroundColor: 'neutrals.fill_pressed',
              color: 'neutrals.text_primary_inverted',
            },
            '&:disabled': {
              backgroundColor: 'neutrals.fill_disabled',
              color: 'neutrals.text_disabled',
              cursor: 'default',
            },
          }
        : {}),
      ...(visual === 'secondary'
        ? {
            borderWidth: '1px',
            borderColor: 'neutrals.stroke_primary',
            backgroundColor: 'neutrals.card_fill_primary',
            color: 'neutrals.text_primary',
            '&:active:enabled': {
              backgroundColor: 'neutrals.card_fill_primary',
              color: 'neutrals.text_primary',
              borderColor: 'neutrals.stroke_primary',
            },
            '&:focus:enabled': {
              backgroundColor: 'neutrals.fill_focused',
              color: 'neutrals.text_primary',
              borderColor: 'neutrals.stroke_primary',
            },
            '&:disabled': {
              backgroundColor: 'neutrals.card_fill_primary',
              color: 'neutrals.text_disabled',
              borderColor: 'neutrals.stroke_primary_disabled',
              cursor: 'default',
            },
          }
        : {}),
      ...(visual === 'ghost'
        ? {
            color: 'neutrals.text_primary',
            '&:active:enabled': {
              color: 'neutrals.text_primary',
            },
            '&:focus:enabled': {
              color: 'neutrals.text_primary',
            },
            '&:disabled': {
              color: 'neutrals.text_disabled',
              cursor: 'default',
            },
          }
        : {}),
      ...(visual === 'accent'
        ? {
            backgroundColor: 'brand.fill_primary',
            color: 'brand.on_fill_primary',
            '&:active:enabled': {
              backgroundColor: 'brand.fill_pressed',
              color: 'brand.on_fill_primary',
            },
            '&:focus:enabled': {
              backgroundColor: 'brand.fill_pressed',
              color: 'brand.on_fill_primary',
            },
            '&:disabled': {
              backgroundColor: 'neutrals.fill_disabled',
              color: 'neutrals.text_disabled',
              cursor: 'default',
            },
            '&:hover:not(:disabled)': {
              backgroundColor: 'neutrals.on_fill_dark_disabled',
            },
          }
        : {}),
      ...(visual === 'primaryOnBrand'
        ? {
            backgroundColor: 'neutrals.on_fill_dark',
            color: 'neutrals.on_fill_light',
            '&:active:enabled': {
              backgroundColor: 'neutrals.on_fill_dark',
              color: 'neutrals.on_fill_light',
            },
            '&:focus:enabled': {
              backgroundColor: 'neutrals.fill_focused',
              color: 'neutrals.on_fill_light',
            },
            '&:disabled': {
              backgroundColor: 'neutrals.on_fill_dark_disabled',
              color: 'neutrals.text_disabled',
              cursor: 'default',
            },
          }
        : {}),
      ...(size === 'small'
        ? {
            fontSize: 'caption.medium_medium',
            py: 'extra_small_2',
            px: 'small',
            minHeight: 40,
          }
        : {}),
      ...(size === 'medium'
        ? {
            fontSize: 'label.medium_medium',
            lineHeight: 'label.medium_medium',
            letterSpacing: 'label.medium_medium',
            fontWeight: 'label.medium_medium',
            padding: 'extra_small_2',
            minHeight: 48,
            width: '100%',
          }
        : {}),
      ...(size === 'large'
        ? {
            fontSize: 'label.large',
            lineHeight: 'label.large',
            letterSpacing: 'label.large',
            fontWeight: 'label.large',
            px: 'extra_small_2',
            py: 18,
            minHeight: 56,
            width: '100%',
          }
        : {}),
      ...(noPadding === true
        ? {
            px: 0,
            py: 0,
            p: 0,
            minHeight: 0,
          }
        : {}),
    })}
    {...rest}
  >
    {children}
  </Button>
);
