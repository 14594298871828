import { StaticImageData } from 'next/dist/shared/lib/get-img-props';
import {
  defaultCdnImageFormat,
  defaultCdnImageQuality,
  liveCdnUrl,
  stagingCdnUrl,
} from './imageCDNSettings';

const cdnImageFunctionsBase = `/cdn-cgi/image/format=${defaultCdnImageFormat}`;

/**
 * Generates a CDN image URL in CGI format.
 *
 * @param {string} cdnurl - The CDN URL.
 * @param {string} url - The original image URL.
 * @param {number} quality - The quality of the image.
 * @param {number} [width] - The width of the image (optional).
 * @param {number} [height] - The height of the image (optional).
 * @return {string} The generated CDN image URL.
 */
const getImageUrlCdnCgiFormat = (
  cdnurl: string,
  url: string,
  quality: number,
  width?: number,
  height?: number,
) => {
  const restOfUrl = url.replace(stagingCdnUrl, '');

  let urlParamsBase = `${cdnImageFunctionsBase}`;

  if (width) {
    urlParamsBase += `,width=${width}`;
  }

  if (height) {
    urlParamsBase += `,height=${height}`;
  }

  urlParamsBase += `,quality=${quality}`;

  return `${cdnurl}${urlParamsBase}${restOfUrl}`;
};

/**
 * Parses the given image URL based on certain criteria.
 *
 * @param {string} url - The image URL to parse.
 * @param {number} [width] - The width of the image (optional).
 * @param {number} [height] - The height of the image (optional).
 * @param {number} [quality=defaultCDNImageQuality] - The quality of the image (default is defaultCDNImageQuality).
 * @return {string} The parsed image URL based on the criteria.
 */
const imageUrlParser = (
  url: string | StaticImageData,
  width?: number,
  height?: number,
  quality: number = defaultCdnImageQuality,
): string => {
  if (url && typeof url !== 'string' && 'src' in url) {
    return url.src;
  }

  const cdnURLCGI = [stagingCdnUrl, liveCdnUrl].find((urlEntry) =>
    url?.startsWith(urlEntry),
  );

  switch (true) {
    case !!cdnURLCGI:
      return getImageUrlCdnCgiFormat(cdnURLCGI, url, quality, width, height);

    default:
      return url;
  }
};

export { imageUrlParser };
