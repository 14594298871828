'use client';

import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import { ToastProvider } from '@bts-web/design-system/component/toast';
import { css } from '@bts-web/utils-style-engine';
import { Toast } from '@bts-web/design-system/component/toast';
import { getNotificationIcon } from './utils/getNotificationIcon';

const NOTIFICATION_DURATION = 5000;

export type TNotificationEntry = {
  open?: boolean;
  title?: string;
  subtitle?: string | React.ReactElement;
  action?: { component: ReactNode; altText: string };
  withClose?: boolean;
  visual?: 'info' | 'error' | 'warning' | 'success' | 'neutral';
  id: string;
  customDuration?: number | 'infinite';
};

export type TNotificationTriggerType = Omit<
  TNotificationEntry,
  'open' | 'id'
> & {
  id?: string;
};

export type INotificationContext = {
  setAppNotification: (notification: TNotificationTriggerType) => void;
  closeAllNotifications: () => void;
};

const NotificationContext = createContext<INotificationContext>({
  setAppNotification: (_: TNotificationTriggerType) => {
    return;
  },
  closeAllNotifications: () => {
    return;
  },
});

export const NotificationContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [notifications, setAppNotifications] = useState<TNotificationEntry[]>(
    [],
  );

  const onNotificationClose = (notifId: string) => {
    setAppNotifications((currentNotifications) =>
      currentNotifications.map((notif) =>
        notif.id === notifId ? { ...notif, open: false } : notif,
      ),
    );

    setTimeout(() => {
      setAppNotifications((currentNotifications) =>
        currentNotifications.filter((notif) => notif.id !== notifId),
      );
    }, 300);
  };

  const triggerNotification = (passedNotif: TNotificationTriggerType) => {
    const isNotificationExistent = notifications.find(
      (notification) => notification.id === passedNotif.id,
    );

    if (isNotificationExistent) {
      return;
    }

    setAppNotifications((currentNotifications) => {
      return [
        ...currentNotifications,
        {
          open: true,
          visual: passedNotif.visual ?? 'info',
          title: passedNotif.title ?? 'Undefined',
          subtitle: passedNotif.subtitle ?? '',
          withClose: passedNotif.withClose ?? false,
          id: `notification-${Math.random()}`,
          customDuration: passedNotif.customDuration,
        },
      ];
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        setAppNotification: triggerNotification,
        closeAllNotifications: () => {
          setAppNotifications([]);
        },
      }}
    >
      <ToastProvider
        duration={999999999}
        viewPortClassName={css({
          position: 'fixed',
          right: 0,
          top: 72,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '390px',
          maxWidth: '100vw',
          margin: 0,
          listStyle: 'none',
          zIndex: 2147483647,
          outline: 'none',
          padding: 'large',
          '& [data-element="toast-title"]': {
            display: 'flex',
            gap: 'extra_small',
            flex: 1,
            fontSize: 'label.medium_medium',
            fontWeight: 'label.medium_medium',
            letterSpacing: 'label.medium_medium',
            lineHeight: 'label.medium_medium',
          },
          '& [data-element="toast-title-container"]': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: 'auto',
            gap: 'extra_small',
            width: '100%',
          },
          '& [data-element="toast-content"]': {
            paddingLeft: 'extra_large',
            fontSize: 'body.medium',
            fontWeight: 'body.medium',
            letterSpacing: 'body.medium',
            lineHeight: 'body.medium',
          },
          '& [data-element="toast-action-container"]': {
            paddingLeft: 'extra_large',
          },
          '& [data-element="icon-visual"]': {
            minWidth: 24,
          },
        })}
      >
        {notifications.map((notification) => {
          let durationValue = NOTIFICATION_DURATION;

          if (notification.customDuration === 'infinite') {
            durationValue = 99999999999999;
          } else if (notification.customDuration) {
            durationValue = notification.customDuration;
          }

          return (
            <Toast
              action={notification.action}
              duration={durationValue}
              withClose={notification.withClose}
              key={notification.id}
              open={notification?.open || false}
              onOpenChange={() => onNotificationClose(notification.id)}
              title={
                <>
                  {getNotificationIcon(notification?.visual || 'info')}
                  <div>{notification.title}</div>
                </>
              }
              subtitle={notification?.subtitle}
              className={css({
                borderRadius: '8px',
                borderWidth: '1px',
                boxShadow: `4px 8px 8px 0px rgba(0, 0, 0, 0.25)`,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 342,
                width: '100%',
                padding: 'small',
                gap: 'extra_small_2',
                '&[data-state="open"]': {
                  animation: 'slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1)',
                },
                '&[data-state="closed"]': {
                  animation: 'slideOut 100ms cubic-bezier(0.16, 1, 0.3, 1)',
                },
                ...(notification?.visual === 'info'
                  ? {
                      background: 'information.fill_focused',
                      borderColor: 'information.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'information.text_primary',
                        '& > iconify-icon': {
                          color: 'information.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'error'
                  ? {
                      background: 'negative.fill_focused',
                      borderColor: 'negative.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'negative.text_primary',
                        '& > iconify-icon': {
                          color: 'negative.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'warning'
                  ? {
                      background: 'warning.fill_focused',
                      borderColor: 'warning.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'warning.text_primary',
                        '& > iconify-icon': {
                          color: 'warning.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'success'
                  ? {
                      background: 'positive.fill_focused',
                      borderColor: 'positive.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'positive.text_primary',
                        '& > iconify-icon': {
                          color: 'positive.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'neutral'
                  ? {
                      background: 'neutrals.fill_focused',
                      borderColor: 'neutrals.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'neutrals.text_primary',
                        '& > iconify-icon': {
                          color: 'neutrals.stroke_primary',
                        },
                      },
                    }
                  : {}),
              })}
            />
          );
        })}
      </ToastProvider>
      {children}
    </NotificationContext.Provider>
  );
};

export const useAppNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useAppNotification must be used within a NotificationContext',
    );
  }

  return context;
};
