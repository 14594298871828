import resourcesToBackend from 'i18next-resources-to-backend';
// type only import ✓ (no json imported)
import type { Namespace, NamespaceNames } from './translation-namespaces.type';

const defaultNS: Namespace = 'general';

// required for the i18n config
const namespaces: NamespaceNames = ['general'];

const supportedLanguages = ['de', 'en'] as const;

const supportedLocaleRegions = ['DE', 'GB'] as const;

export const defaultLanguage: Language = isSupportedLanguage(
  process.env.NEXT_PUBLIC_LANGUAGE,
)
  ? process.env.NEXT_PUBLIC_LANGUAGE
  : supportedLanguages[0];

function isSupportedLanguage(lang?: string): lang is Language {
  const languages: string[] = [...supportedLanguages];

  return lang !== undefined && languages.includes(lang);
}

export const translationOptions = {
  debug: false,
  supportedLngs: supportedLanguages,
  ns: namespaces,
  defaultNS,
  fallbackLng: defaultLanguage,
} as const;

export type Language = (typeof supportedLanguages)[number];

export type LanguageTitle = 'English' | 'Deutsch';

export type DefaultNS = typeof defaultNS;

export const codeSplitBackend = resourcesToBackend(
  (language: string, namespace: string) =>
    import(`../generated/i18n/locales/${language}/${namespace}.json`),
);

type Region = (typeof supportedLocaleRegions)[number];

export type Locale = `${Language}-${Region}`;

type LocaleForLanguage = {
  [key in Language]: Locale[];
};

const suportedLocalesForLanguage: LocaleForLanguage = {
  de: ['de-DE'],
  en: ['en-GB'],
};

const regionByLanguage: Record<Language, Region> = {
  de: 'DE',
  en: 'GB',
};

export const languageTitleByCode: Record<Language, LanguageTitle> = {
  de: 'Deutsch',
  en: 'English',
};

export const getLocale = (language: Language) => {
  const languageRegion = regionByLanguage[language];

  const locale = `${language}-${languageRegion}` as const;

  if (
    suportedLocalesForLanguage &&
    suportedLocalesForLanguage[language] &&
    suportedLocalesForLanguage[language]?.includes(locale)
  ) {
    return locale;
  }

  console.warn(
    `Could not get locale for language ${language} and region ${languageRegion}`,
  );

  return (
    suportedLocalesForLanguage &&
    suportedLocalesForLanguage[language] &&
    suportedLocalesForLanguage[language][0]
  );
};

export const defaultLocale = getLocale(defaultLanguage);
