import { css } from '@bts-web/utils-style-engine';

export const SkeletonElement = ({
  width,
  height,
  borderRadius = '',
  testId,
  maxWidth,
}: {
  width?: string;
  maxWidth?: string;
  height: string;
  borderRadius?: string;
  testId?: string;
}) => (
  <div
    className={css({
      width,
      height,
      background: 'neutrals.fill_focused',
      borderRadius,
      flexShrink: 0,
      maxWidth,
    })}
    data-testid={testId || 'skeleton-element'}
  ></div>
);
