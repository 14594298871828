'use client';

import { css } from '@bts-web/utils-style-engine';
import {
  TNotificationTriggerType,
  useAppNotification,
} from '../NotificationContextProvider';

const playItems: TNotificationTriggerType[] = [
  {
    title: 'Success',
    visual: 'success',
  },
  {
    title: 'Error',
    visual: 'error',
  },
  {
    title: 'Warning',
    visual: 'warning',
  },
  {
    title: 'Neutral',
    visual: 'neutral',
  },
  {
    title: 'Neutral duration infinite',
    visual: 'neutral',
    customDuration: 'infinite',
  },
  {
    title: 'Info',
    visual: 'info',
  },
  {
    title: 'Info with close',
    visual: 'info',
    subtitle: 'With subtitle',
    withClose: true,
  },
];

export const NotificationPlayGround = () => {
  const { setAppNotification } = useAppNotification();

  return (
    <ul
      className={css({
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'small',
      })}
    >
      {playItems.map((item) => {
        return (
          <button
            key={item.title}
            onClick={() => setAppNotification(item)}
            className={css({
              border: '1px solid',
              borderRadius: '6px',
              padding: 'extra_small_2',
            })}
          >
            {item.title}
          </button>
        );
      })}
    </ul>
  );
};
