import { ReactNode } from 'react';
import { Provider, Viewport } from '@radix-ui/react-toast';

export interface ToastProviderProps {
  internal: {
    viewPortClassName: string;
    children: ReactNode;
  };
  external: {
    duration?: number;
  };
}

export const ToastProvider = ({
  children,
  viewPortClassName,
  duration = 5000,
}: ToastProviderProps['internal'] & ToastProviderProps['external']) => {
  return (
    <Provider swipeDirection="right" duration={duration}>
      {children}
      <Viewport
        className={viewPortClassName}
        data-element="toast-viewport"
        data-testid="toast-viewport"
      />
    </Provider>
  );
};
