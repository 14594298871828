'use client';

import { FC } from 'react';
import { NavigationBackButton } from '../NavigationBackButton/NavigationBackButton.use-client';
import { NavigationBackButtonComponentProps } from '../../common/types';

// This client-side wrapper is necessary for passing the base Button component,
// Otherwise, it will return an error (if Button is passed from the page which is server side)
const NavigationBackButtonWrap: FC<
  Omit<NavigationBackButtonComponentProps, 'ButtonBase'>
> = ({ handleVirtualNavigation, isDisabled, noPadding }) => {
  return (
    <NavigationBackButton
      noPadding={noPadding}
      isDisabled={isDisabled}
      handleVirtualNavigation={handleVirtualNavigation}
    />
  );
};

export { NavigationBackButtonWrap };
