import { Icon } from '@bts-web/design-system/component/icon';
import { TNotificationEntry } from '../NotificationContextProvider';

export const getNotificationIcon = (visual: TNotificationEntry['visual']) => {
  switch (visual) {
    case 'success':
      return (
        <Icon
          icon="checkmark-circle"
          size="24"
          theme="regular"
          data-element="icon-visual"
          data-testid="icon-success"
        />
      );

    case 'error':
      return (
        <Icon
          icon="dismiss-circle"
          size="24"
          theme="regular"
          data-element="icon-visual"
          data-testid="icon-error"
        />
      );

    case 'warning':
      return (
        <Icon
          icon="error-circle"
          size="24"
          theme="regular"
          data-element="icon-visual"
          data-testid="icon-warning"
        />
      );

    case 'neutral':
      return (
        <Icon
          icon="circle-placeholder"
          size="24"
          theme="regular"
          data-element="icon-visual"
          data-testid="icon-neutral"
        />
      );

    case 'info':
      return (
        <Icon
          icon="info"
          size="24"
          theme="regular"
          data-element="icon-visual"
          data-testid="icon-info"
        />
      );
  }
};
