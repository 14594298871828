export type PrecisionFloat =
  | {
      value: string;
      precision?: number;
    }
  | null
  | undefined;

/*
 For the moment 8 is the maximum number of decimals
 supported by the API for asset amount trade value
 and 2 for fiat amount trade value.
*/
export enum MaximumFractionDigits {
  FIAT = 2,
  ASSET = 8,
}

/* 
  Standard financial rounding rules by decimal
 */
export const getRoundedPrecision = (field: PrecisionFloat): string => {
  const { precision } = field || {};

  const fieldValue = (field && field.value) || '';

  if (precision != null) {
    let roundedValue = Number(fieldValue);

    const p = Math.pow(10, precision);

    const scaledNumber = roundedValue * p;

    roundedValue = Math.round(scaledNumber) / p;

    return roundedValue.toFixed(precision);
  }

  return fieldValue;
};

export const getPrecisionFloatValue = (field: PrecisionFloat): string =>
  (field && field.value) || '';

export const extractPrecisionFloatValueWithDecimalsForNonUiComparisons = (
  valueObject: PrecisionFloat,
) => {
  if (!valueObject || !valueObject.value || !valueObject.precision) return 0;

  return Number(valueObject.value) / Math.pow(10, valueObject.precision);
};
